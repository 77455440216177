<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>

    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-form-item prop="name" label="名称">
        <el-input size="small" v-model="formData.name" placeholder="名称"></el-input>
      </el-form-item>
      <el-form-item label="排序值" prop="sort" label-width="100px">
        <el-input-number v-model="formData.sort" :min="0" label="排序值"></el-input-number>
      </el-form-item>
      <el-form-item label=" ">
        <el-button size="small" type="" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {addCategory, editCategory, detail} from './api'
import {DICT_SYSTEM_SEX, DICT_SYSTEM_STATUS} from '@/enum/dict'

export default {
  name: 'AddOrEdit',

  data() {
    return {
      options: [],
      DICT_SYSTEM_SEX,
      DICT_SYSTEM_STATUS,

      formData: {
        name: '',
        sort: 0,
      },

      rules: {
        name: [{required: true, message: '分类名称不能为空', trigger: 'blur'}],
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getAdminDetail()
  },

  methods: {
    updateSex(val) {
      this.formData.sex = val
    },
    updateStatus(val) {
      this.formData.status = val
    },



    save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.formData.id) {
            // 编辑
            await editCategory(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            // 新增
            await addCategory(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        } else {
          return false
        }
      })
    },
    async getAdminDetail() {
      this.formData = await detail(this.$route.params.id)
    },
    cancel() {
      this.$router.back(-1)
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
