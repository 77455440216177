import {
    http
} from '@/http/axios.js'


// 列表
export function getList(params) {
    return http({
        url: `/company/download/domain/page`,
        method: 'get',
        params
    })
}



export function removeCategory(data) {
    return http({
        url: `/company/download/domain/del`,
        method: 'post',
        data
    })
}
export function addCategory(data) {
    return http({
        url: `/company/download/domain/add`,
        method: 'post',
        data
    })
}

export function editCategory(data) {
    return http({
        url: `/company/download/domain/edit`,
        method: 'post',
        data
    })
}

export function detail(id) {
    return http({
        url: `/company/download/domain/detail`,
        method: 'get',
        params: {id : id}
    })
}
