const downloadRouter = {
    path: '/download',
    name: 'download',
    redirect: '/download/list',
    meta: {
        icon: 'el-icon-s-goods',
        name: '下载专区'
    },
    component: () => import('@/views/download/index.vue'),
    children: [
        {
            path: '/download/category/list',
            name: 'DownloadCategory',
            component: () => import('@/views/download/download-category/index.vue'),
            meta: {
                name: '下载分类'
            }
        },
        {
            path: '/download/category/add',
            name: 'downloadCategoryAdd',
            component: () => import('@/views/download/download-category/addEdit.vue'),
            meta: {
                name: '创建分类',
                hidden: true
            }
        },
        {
            path: '/download/category/edit/:id?',
            name: 'downloadCategoryEdit',
            component: () => import('@/views/download/download-category/addEdit.vue'),
            meta: {
                name: '编辑分类',
                hidden: true
            }
        },


        {
            path: '/download/domain/list',
            name: 'DownloadDomain',
            component: () => import('@/views/download/download-domain/index.vue'),
            meta: {
                name: '领域'
            }
        },
        {
            path: '/download/domain/add',
            name: 'downloadDomainAdd',
            component: () => import('@/views/download/download-domain/addEdit.vue'),
            meta: {
                name: '创建领域',
                hidden: true
            }
        },
        {
            path: '/download/domain/edit/:id?',
            name: 'downloadDomainEdit',
            component: () => import('@/views/download/download-domain/addEdit.vue'),
            meta: {
                name: '编辑领域',
                hidden: true
            }
        },

        {
            path: '/download/list',
            name: 'DownloadList',
            component: () => import('@/views/download/download-manage/index.vue'),
            meta: {
                name: '下载列表'
            }
        },
        {
            path: '/download/add',
            name: 'DownloadAdd',
            component: () => import('@/views/download/download-manage/addEdit.vue'),
            meta: {
                name: '创建下载内容',
                hidden: true
            }
        },
        {
            path: '/download/edit/:id?',
            name: 'DownloadEdit',
            component: () => import('@/views/download/download-manage/addEdit.vue'),
            meta: {
                name: '编辑下载内容',
                hidden: true
            }
        },

        {
            path: '/download/user/list',
            name: 'DownloadUserList',
            component: () => import('@/views/download/user/list.vue'),
            meta: {
                name: '下载会员'
            }
        },
        {
            path: '/download/user/edit/:id?',
            name: 'DownloadUserEdit',
            component: () => import('@/views/download/user/addEdit.vue'),
            meta: {
                name: '用户编辑',
                hidden: true
            }
        },
        {
            path: '/download/user/edit/:id?',
            name: 'downloadUserEdit',
            component: () => import('@/views/download/user/addEdit.vue'),
            meta: {
                name: '用户购买记录',
                hidden: true
            }
        },

        {
            path: '/download/user/history-download/:id?',
            name: 'DownloadUserHistoryList',
            component: () => import('@/views/download/user/download-list.vue'),
            meta: {
                name: '历史下载记录',
                hidden: true
            }
        },

        {
            path: '/download/user/history-buy-list/:id?',
            name: 'DownloadUserBuyList',
            component: () => import('@/views/download/user/download-buy-list.vue'),
            meta: {
                name: '会员购买记录',
                hidden: true
            }
        },


        // {
        //     path: '/download/coursenotes/list',
        //     name: 'CoursenotesList',
        //     component: () => import('@/views/coursenotes/coursenotes-manage/index.vue'),
        //     meta: {
        //         name: '课程资料列表'
        //     }
        // },
        // {
        //     path: '/download/coursenotes/add',
        //     name: 'CoursenotesAdd',
        //     component: () => import('@/views/coursenotes/coursenotes-manage/addEdit.vue'),
        //     meta: {
        //         name: '创建课程资料',
        //         hidden: true
        //     }
        // },
        // {
        //     path: '/download/coursenotes/edit/:id?',
        //     name: 'CoursenotesEdit',
        //     component: () => import('@/views/coursenotes/coursenotes-manage/addEdit.vue'),
        //     meta: {
        //         name: '编辑课程资料',
        //         hidden: true
        //     }
        // },


        {
            path: '/download/config',
            name: 'MemberConfig',
            component: () => import('@/views/operation/member/list.vue'),
            meta: {name: '会员配置'}
        },
    ]
}

export default downloadRouter
